.App {
  text-align:start;
}

.App-logo {
  margin-top: 40px;
  height: 30vmin;
  width: 30vmin;
  border-radius: 60%;
  
  /* pointer-events: none; */
}
.App-logo-grid{

  height: 20vmin;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;

  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body{
  background-color: #282c34;
}
.App-link {
  color: #61dafb;
}


.ownGrid {
  justify-content: center;

  align-items: center;
  display: grid;
  grid-template-columns: auto auto  auto;
  grid-gap: 20px;
  padding: 10px;
}

.gridItem {
  background-color: #282c34;
  height: 20vmin;
  /* min-height: 200px; */
  background-color: #eee;
}

.copy-button{
  background-color: #4CAF50; 
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
